import { Routes } from '@angular/router';
import { TitlePageContainerComponent } from './titles/title-page-container/title-page-container.component';
import { TitleResolverService } from './titles/title-resolver.service';
import { CrupdateListComponent } from './lists/crupdate-list/crupdate-list.component';
import { SeasonPageComponent } from './titles/components/season-page/season-page.component';
import { MEDIA_TYPE } from './media-type';
import { EpisodePageComponent } from './titles/components/episode-page/episode-page.component';
import { PersonPageComponent } from './people/person-page/person-page.component';
import { PersonResolverService } from './people/person-resolver.service';
import { SearchPageComponent } from './search/search-page/search-page.component';
import { SearchResultsResolverService } from './search/search-page/search-results-resolver.service';
import { ListPageComponent } from './lists/list-page/list-page.component';
import { UserListsComponent } from './lists/user-lists/user-lists.component';
import { BrowseTitlesComponent } from './titles/components/browse-titles/browse-titles.component';
import { FullCreditsPageComponent } from './titles/components/full-credits-page/full-credits-page.component';
import { NewsArticleComponent } from './news/news-article/news-article.component';
import { NewsIndexComponent } from './news/news-index/news-index.component';
import { PeopleIndexComponent } from './people/people-index/people-index.component';
import { CheckPermissionsGuard } from '@common/guards/check-permissions-guard.service';
import { AuthGuard } from '@common/guards/auth-guard.service';
const ɵ0 = { permissions: ['titles.view'], name: 'browse', willSetSeo: true }, ɵ1 = { permissions: ['titles.view'], name: MEDIA_TYPE.MOVIE }, ɵ2 = { permissions: ['titles.view'], name: MEDIA_TYPE.MOVIE, fullCredits: true }, ɵ3 = { permissions: ['titles.view'], name: MEDIA_TYPE.SEASON }, ɵ4 = { permissions: ['titles.view'], name: MEDIA_TYPE.EPISODE }, ɵ5 = { permissions: ['titles.view'], name: MEDIA_TYPE.MOVIE }, ɵ6 = { permissions: ['people.view'], name: 'people', willSetSeo: true }, ɵ7 = { permissions: ['people.view'], name: MEDIA_TYPE.PERSON, willSetSeo: true }, ɵ8 = { permissions: ['lists.create'] }, ɵ9 = { permissions: ['lists.view'], name: 'list', willSetSeo: true }, ɵ10 = { permissions: ['lists.update'] }, ɵ11 = { name: 'search', willSetSeo: true }, ɵ12 = { watchlist: true, name: 'watchlist', willSetSeo: true }, ɵ13 = { name: 'Your Lists' }, ɵ14 = { permissions: ['news.view'], name: 'news', willSetSeo: true }, ɵ15 = { permissions: ['news.view'], willSetSeo: true }, ɵ16 = { permissions: ['titles.view'], name: MEDIA_TYPE.MOVIE }, ɵ17 = { permissions: ['titles.view'], name: MEDIA_TYPE.MOVIE, fullCredits: true }, ɵ18 = { permissions: ['titles.view'], name: MEDIA_TYPE.SEASON }, ɵ19 = { permissions: ['titles.view'], name: MEDIA_TYPE.EPISODE }, ɵ20 = { permissions: ['titles.view'], name: MEDIA_TYPE.MOVIE }, ɵ21 = { permissions: ['people.view'], name: MEDIA_TYPE.PERSON, willSetSeo: true };
const routes = [
    {
        path: '',
        canActivate: [CheckPermissionsGuard],
        canActivateChild: [CheckPermissionsGuard],
        children: [
            // browse
            {
                path: 'movies',
                redirectTo: 'browse'
            },
            {
                path: 'series',
                redirectTo: 'browse'
            },
            {
                path: 'browse',
                component: BrowseTitlesComponent,
                data: ɵ0,
            },
            // titles
            {
                path: 'movies/:titleId',
                redirectTo: 'titles/:titleId'
            },
            {
                path: 'series/:titleId',
                redirectTo: 'titles/:titleId'
            },
            {
                path: 'titles/:titleId/:titleName',
                component: TitlePageContainerComponent,
                resolve: { api: TitleResolverService },
                data: ɵ1
            },
            {
                path: 'titles/:titleId/:titleName/full-credits',
                component: FullCreditsPageComponent,
                resolve: { api: TitleResolverService },
                data: ɵ2
            },
            {
                path: 'series/:titleId/seasons/:seasonNumber',
                redirectTo: 'titles/:titleId/season/:seasonNumber'
            },
            {
                path: 'titles/:titleId/:titleName/season/:seasonNumber',
                component: SeasonPageComponent,
                resolve: { api: TitleResolverService },
                data: ɵ3
            },
            {
                path: 'series/:titleId/seasons/:seasonNumber/episodes/:episodeNumber',
                redirectTo: 'titles/:titleId/season/:seasonNumber/episode/:episodeNumber'
            },
            {
                path: 'titles/:titleId/:titleName/season/:seasonNumber/episode/:episodeNumber',
                component: EpisodePageComponent,
                resolve: { api: TitleResolverService },
                data: ɵ4
            },
            {
                path: 'titles/:titleId/:titleName/season/:seasonNumber/episode/:episodeNumber/full-credits',
                component: FullCreditsPageComponent,
                resolve: { api: TitleResolverService },
                data: ɵ5
            },
            // people
            {
                path: 'people',
                component: PeopleIndexComponent,
                data: ɵ6
            },
            {
                path: 'people/:id/:name',
                component: PersonPageComponent,
                resolve: { api: PersonResolverService },
                data: ɵ7
            },
            // lists
            {
                path: 'lists/new',
                pathMatch: 'full',
                component: CrupdateListComponent,
                data: ɵ8,
            },
            {
                path: 'lists/:id',
                component: ListPageComponent,
                data: ɵ9,
            },
            {
                path: 'lists/:id/edit',
                component: CrupdateListComponent,
                data: ɵ10,
            },
            // search
            {
                path: 'search',
                component: SearchPageComponent,
                resolve: { api: SearchResultsResolverService },
                data: ɵ11,
            },
            // user
            {
                path: 'watchlist',
                component: ListPageComponent,
                canActivate: [AuthGuard],
                data: ɵ12,
            },
            {
                path: 'lists',
                component: UserListsComponent,
                canActivate: [AuthGuard],
                data: ɵ13
            },
            // news
            {
                path: 'news',
                component: NewsIndexComponent,
                data: ɵ14
            },
            {
                path: 'news/:id',
                component: NewsArticleComponent,
                data: ɵ15
            },
            // LEGACY (Without name/title in url)
            {
                path: 'titles/:titleId',
                component: TitlePageContainerComponent,
                resolve: { api: TitleResolverService },
                data: ɵ16
            },
            {
                path: 'titles/:titleId/full-credits',
                component: FullCreditsPageComponent,
                resolve: { api: TitleResolverService },
                data: ɵ17
            },
            {
                path: 'titles/:titleId/season/:seasonNumber',
                component: SeasonPageComponent,
                resolve: { api: TitleResolverService },
                data: ɵ18
            },
            {
                path: 'titles/:titleId/season/:seasonNumber/episode/:episodeNumber',
                component: EpisodePageComponent,
                resolve: { api: TitleResolverService },
                data: ɵ19
            },
            {
                path: 'titles/:titleId/season/:seasonNumber/episode/:episodeNumber/full-credits',
                component: FullCreditsPageComponent,
                resolve: { api: TitleResolverService },
                data: ɵ20
            },
            {
                path: 'people/:id',
                component: PersonPageComponent,
                resolve: { api: PersonResolverService },
                data: ɵ21
            },
        ]
    },
];
export class SiteRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21 };
