import { NgZone } from '@angular/core';
import { UploadQueueItem } from './upload-queue-item';
import { BehaviorSubject, concat, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { UploadEventTypes } from '../utils/upload-progress-event';
import { UploadsApiService } from '../uploads-api.service';
import { DefaultUploadValidator } from '../validation/default-upload-validator';
import * as i0 from "@angular/core";
import * as i1 from "../uploads-api.service";
import * as i2 from "../validation/default-upload-validator";
export class UploadQueueService {
    constructor(api, zone, defaultValidator) {
        this.api = api;
        this.zone = zone;
        this.defaultValidator = defaultValidator;
        this.uploads$ = new BehaviorSubject([]);
        this.totalProgress$ = new BehaviorSubject(0);
        this.uploadsAdded$ = new Subject();
    }
    isEmpty() {
        return !this.uploads$.value.length;
    }
    getAllCompleted() {
        return this.uploads$.value
            .filter(queueItem => queueItem.completed)
            .map(queueItem => queueItem.fileEntry);
    }
    getPendingCount() {
        return this.uploads$.pipe(map(uploads => {
            return uploads.filter(upload => !upload.completed).length;
        }));
    }
    getCompletedCount() {
        return this.uploads$.pipe(map(uploads => {
            return uploads.filter(upload => upload.completed).length;
        }));
    }
    updateTotalProgress() {
        const progress = this.uploads$.value.map(upload => upload.meta$.value.progress || 0);
        this.totalProgress$.next(progress.reduce((p, c) => p + c, 0) / progress.length);
    }
    totalProgress() {
        return this.totalProgress$.asObservable();
    }
    start(files, config = {}) {
        const uploads = this.transformUploads(files, config.validator || this.defaultValidator);
        this.uploads$.next(this.uploads$.value.concat(uploads));
        this.uploadsAdded$.next(uploads);
        const requests = uploads
            .filter(upload => !upload.hasError)
            .map((upload, key) => {
            return this.api.upload(files[key], config).pipe(takeUntil(upload.canceled$), map(e => {
                // assign queue item ID to upload completed response so
                // file entry can be matched to specific upload queue item
                if (e.name === UploadEventTypes.COMPLETED) {
                    e.body.queueItemId = upload.id;
                }
                return e;
            }), tap(response => this.handleUploadEvent(response, upload), response => this.handleUploadFailure(response, upload)));
        });
        return concat(...requests)
            .pipe(filter(e => e.name === UploadEventTypes.COMPLETED), map((e) => e.body));
    }
    updateProgress(id, e) {
        const queueItem = this.find(id);
        if (!queueItem)
            return;
        queueItem.update({
            eta: e.eta,
            speed: e.speed,
            progress: e.progress,
            totalBytes: e.totalBytes,
            completedBytes: e.completedBytes,
        });
        this.updateTotalProgress();
    }
    completeUpload(id, response) {
        const queueItem = this.find(id);
        if (!queueItem)
            return;
        queueItem.uploadedResponse$.next(response);
        queueItem.fileEntry = response.fileEntry;
        queueItem.complete();
    }
    errorUpload(id, message = '') {
        this.find(id).addError(message);
    }
    reset() {
        this.uploads$.value.forEach(u => u.cancel());
        this.uploads$.next([]);
    }
    remove(id) {
        const i = this.uploads$.value.findIndex(u => u.id === id), upload = this.uploads$.value[i];
        upload.completed ? upload.finalize() : upload.cancel();
        this.uploads$.value.splice(i, 1);
        this.uploads$.next(this.uploads$.value);
    }
    find(id) {
        return this.uploads$.value.find(u => u.id === id);
    }
    /**
     * Transform specified files into upload queue items.
     */
    transformUploads(files, validator) {
        return files.map(file => {
            const activeUpload = new UploadQueueItem(file);
            // validate upload
            if (validator) {
                const result = validator.validate(file);
                if (result.failed)
                    activeUpload.addError(result.errorMessage);
            }
            // remove upload, if it is canceled by user
            activeUpload.canceled$.subscribe(() => {
                this.remove(activeUpload.id);
            });
            return activeUpload;
        });
    }
    handleUploadEvent(event, upload) {
        if (event.name === UploadEventTypes.PROGRESS) {
            this.zone.run(() => {
                this.updateProgress(upload.id, event);
            });
        }
        else if (event.name === UploadEventTypes.COMPLETED) {
            this.zone.run(() => {
                this.completeUpload(upload.id, event.body);
            });
        }
    }
    handleUploadFailure(response, upload) {
        const msg = response.messages ? response.messages.file : '';
        this.errorUpload(upload.id, msg);
    }
}
UploadQueueService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadQueueService_Factory() { return new UploadQueueService(i0.ɵɵinject(i1.UploadsApiService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i2.DefaultUploadValidator)); }, token: UploadQueueService, providedIn: "root" });
